<template>
  <div class="mine">
    <TitleBar
      :left-arrow="false"
    />
    <van-tabs v-model="active" sticky line-width="80px" line-height="1px" title-active-color="#0D66DA" color="#0D66DA" @change="$_changeTab">
      <van-tab v-for="(item, index) in tabs" :key="index" :title="item.title">
        <div class="pullup">
          <div :id="item.id" class="pullup-wrapper">
            <div>
              <div v-for="item2 in item.datas" :key="item2.id" class="tab-item">{{item2.name}}---{{active}}</div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { Tab, Tabs } from 'vant'
import { Toast } from 'mand-mobile'
import BScroll from '@better-scroll/core'
import Pullup from '@better-scroll/pull-up'

BScroll.use(Pullup)

export default {
  name: 'Mine',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  data () {
    return {
      active: 0,
      tabs: [
        {
          id: 'tab0',
          title: '标签页一',
          datas: [
            { id: '001', name: '001' },
            { id: '002', name: '002' },
            { id: '003', name: '003' },
            { id: '004', name: '004' },
            { id: '005', name: '005' },
            { id: '006', name: '006' },
            { id: '007', name: '007' },
            { id: '008', name: '008' },
            { id: '009', name: '009' },
            { id: '010', name: '010' },
            { id: '011', name: '011' },
            { id: '012', name: '012' },
            { id: '013', name: '013' },
            { id: '014', name: '014' },
            { id: '015', name: '015' }
          ]
        },
        {
          id: 'tab1',
          title: '标签页二',
          datas: [
            { id: '001', name: 'a' },
            { id: '002', name: 'b' },
            { id: '003', name: 'c' },
            { id: '004', name: 'd' },
            { id: '005', name: 'e' },
            { id: '006', name: 'f' },
            { id: '007', name: 'g' },
            { id: '008', name: 'h' },
            { id: '009', name: 'i' }
          ]
        },
        {
          id: 'tab2',
          title: '标签页三',
          datas: [
            { id: '001', name: 'A' },
            { id: '002', name: 'B' },
            { id: '003', name: 'C' },
            { id: '004', name: 'D' },
            { id: '005', name: 'E' }
          ]
        }
      ],
      bscroll: null,
      isPullUpLoad: false,
      bscrollArray: []
    }
  },
  mounted () {
    this.initBscroll()
  },
  methods: {
    initBscroll () {
      this.$_changeTab(0)
    },

    $_changeTab (active) {
      setTimeout(() => {
        const tab = this.tabs[active]
        const tmpBscroll = tab.bscroll
        if (!tmpBscroll) {
          const bscroll = new BScroll('#' + tab.id, {
            scrollY: true,
            pullUpLoad: true
          })
          tab.bscroll = bscroll
          bscroll.on('pullingUp', this.pullingUpHandler)
        }
      }, 50)
    },

    async pullingUpHandler () {
      this.isPullUpLoad = true

      await this.requestData()
      console.log('----->' + this.active)
      const bscroll = this.tabs[this.active].bscroll

      bscroll.finishPullUp()
      bscroll.refresh()
      this.isPullUpLoad = false
    },

    requestData () {
      Toast.loading('加载中...', 0, true)
      setTimeout(() => {
        console.log('加载数据')
        Toast.hide()
      }, 3000)
    }
  }
}
</script>

<style lang="less" scoped>
.mine {
  .pullup {
    position: fixed;
    top: 1.17333rem;
    right: 0;
    bottom: 1.33333rem;
    left: 0;
  }
  height: 100%;
  .pullup-wrapper {
    height: 100%;
    overflow: hidden;
  }
  .tab-item {
    width: 100%;
    height: 120px;
    border: 1px solid #eee;
  }
}
</style>
